import React from "react";
import { graphql, Link } from "gatsby";
import "../styles/styles.css"
// import { GatsbyImage } from 'gatsby-plugin-image';
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import ballon from "../images/mingrui-he-57AQULDcLq0-unsplash.jpeg";
import NavBarTwo from "../components/NavbarTwo";
import { SEO } from "../components/seo";
import Footer from "../components/Footer";
import Topics from "../components/Topics";
import ContactDetails from "../components/ContactDetails";

const Container = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 4rem;
grid-auto-rows: auto auto 500px;

}
`
 

const indexPage = ({data})=>{
   
    return (
        
        <>

<NavBarTwo/>


<section className="bg-hero-home xl:bg-hero-home-xl flex flex-col justify-end sm:block pb-16 text-gray-400 bg-gray-900 body-font bg-no-repeat  bg-center	 bg-cover	 h-screen">
    <div className="container mx-auto max-w-7xl flex  px-5 sm:py-64 sm:flex-row flex-col items-center">
      <div className="lg:flex-grow max-w-sm md:max-w-lg md:w-1/2 -lg:pr-24 -md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center  bg-dark/40 py-6 px-4 rounded">
      <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
        <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white">Das Rezept für Ihren<span> </span>
          <br className=""/><span className="text-primary">
          E-Commerce Erfolg </span>
        </h1>
        <br/>
        <p className="text-sm md:text-base mb-8 leading-relaxed text-white max-w-sm">Bei Themen von A wie Amazon über den eigenen Onlineshop bis hin zu Z wie Zalando:<br/> Individuelle Lösungen rund ums Thema Onlinehandel</p>
        <br/>
        <div className="flex-col lg:flex-row justify-center">
          <a href="#main" className=" inline-flex text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary rounded-full text-lg mb-6">mehr erfahren
            </a>

          <a href="#contacts" className="sm:ml-0 lg:ml-4  inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded-full text-lg">Kontaktaufnahme</a>
        </div>
      </div>
    
    </div>
  </section>      


            

<section className="text-gray-600 body-font" id="main">
  <div className="container max-w-7xl mx-auto flex px-5 py-48 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
     <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
      <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Die E-Commerce Agentur
        <br className="hidden lg:inline-block"/> im Raum Stuttgart
      </h1>
      <p className="sm:text-lg mb-8 leading-relaxed">Wir sind eine E-Commerce Agentur mit Sitz im Raum Stuttgart. 

Die Mission: das Warenangebot und die Dienstleistungen unserer Kunden erfolgreich und zukunftssicher auf den richtigen Online-Märkten zu platzieren.

 
Dabei ist es egal, ob beim Einstieg in den Onlinehandel oder bei Ausbau und Optimierung bestehender Aktivitäten: 
Unser Leistungsspektrum umfasst alle wichtigen Bereiche des E-Commerce von der Konzeption über die Implementierung bis zum laufenden Betrieb.
Dabei dreht es sich in unseren Kundenprojekten stets darum, durch individuelle Konzepte und Lösungen die beste Wirkungskraft zu erzielen.

</p>
      
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      
    <Image {...data.sanityLevelOne.image}  width={250}     style={{


width: '100%',

objectFit: 'cover',


opacity: '1',
transition: 'opacity 500ms ease 0s'

}} alt="E-Commerce"  />
    </div>
  </div>
</section>   

<section>

  <div className="container mx-auto max-w-7xl pb-24 px-5">
  <div class="w-24 h-1 bg-primary rounded mt-2 mb-4 mx-auto"></div>
    <h2 className="title-font sm:text-3xl text-3xl text-center mb-24">Unsere Dienstleistungen</h2>
    <div className="flex flex-col md:flex-row">

    <div className="one md:w-1/3  py-5 px-4 flex-col bg-gray-300
rounded flex flex-col">
      <div className="md:h-32">
      <h3 className="sm:text-3xl text-3xl text-center mb-8">Marktplätze und Amazon</h3>

      </div>
      

      <ul className="list-inside leading-relaxed   grow px-4 py-6 text-gray-900">
        <li className="list-check pb-2">Full Service Amazon Betreuung</li>
        <li className="list-check pb-2">
Account Management</li>
        <li className="list-check pb-2">
"Gesundheits-Check" Amazon Konto</li>
        <li className="list-check pb-2">
Einrichtung Marktplatzkonten</li>
        <li className="list-check pb-2">
Anbindung Schnittstellen</li>
        <li className="list-check pb-2">
Produktdatenpflege und Content-Management</li>
        <li className="list-check pb-2">
Listing-Optimierung</li>
        <li className="list-check pb-2">
Amazon-SEO</li>
        <li className="list-check pb-2">
PPC Werbung</li>
        <li className="list-check pb-2">
Krisenmanagement bei gesperrtem oder gefährdetem Amazon-Konto</li>
        <li className="list-check pb-2">
Kundenservice</li>
      </ul>
      <div className="py-8 text-center">

      <Link className="bg-primary text-dark  rounded-full py-2 px-6 mx-auto" to="marktplaetze/">Marktplatzlösungen</Link>
      </div>
    </div>
    <div className="two md:w-1/3  px-4 py-5 flex flex-col">
    <div className="md:h-32">
      <h3 className="sm:text-3xl text-3xl text-center mb-8">E-Commerce</h3>
      </div>
      <ul className="list-inside py-6
text-gray-900 leading-relaxed  px-4">

      <li className="list-check pb-2">Neuplanung und Implementierung Online-Shops</li>
      <li className="list-check pb-2">Strategie Beratung</li>
      <li className="list-check pb-2">D2C Rundum-Lösungen</li>
      <li className="list-check pb-2">Rundum Service "Einstieg E-Commerce"</li>
      <li className="list-check pb-2">Beratung Auswahl Shopsysteme</li>
      <li className="list-check pb-2">E-Commerce ERP (Plentymarkets u.a.)</li>
      <li className="list-check pb-2">Schnittstellen und Automatisierung</li>
      <li className="list-check pb-2">Logistik- und Versandthemen</li>
      <li className="list-check pb-2">SEA/Klickbasierte Werbung (Google Ads, Amazon, Facebook u.a.)</li>
      <li className="list-check pb-2">Preissuchmaschinen</li>
      <li className="list-check pb-2">Analytics</li>
      </ul>
      <div className="py-8 text-center">
      <Link className="bg-primary text-dark rounded-full py-2 px-6" to="ecommerce/">E-Commerce</Link>
      </div>
    </div>
    <div className="three md:w-1/3  px-4 py-5 flex flex-col rounded bg-gray-300">
      <div className="md:h-32">
      <h3 className="sm:text-3xl text-3xl text-center mb-8">Webseiten und CMS</h3>
      </div>
      <ul className="list-inside py-6
text-gray-900 leading-relaxed  px-4 grow">
      <li className="list-check pb-2">Konzeption und Programmierung individueller Webseiten</li>
      <li className="list-check pb-2">Blitzschnelle moderne Jamstack Webseiten</li>
      <li className="list-check pb-2">CMS Lösungen mit Wordpress</li>
      <li className="list-check pb-2">Headless CMS Lösungen</li>
      <li className="list-check pb-2">Wordpress Anbindung/Migration zu Gatsby</li>
      <li className="list-check pb-2">Nutzerschulungen</li>
      <li className="list-check pb-2">Content Management und Datenpflege</li>

      </ul>
      <div className="py-8 text-center justify-self-end">
      <Link className="bg-primary text-dark rounded-full py-2 px-6 " to="webentwicklung/">Webseiten und CMS</Link>

      </div>
    </div>

    </div>

  </div>
</section>

<Topics/>

<section id="contacts" className="text-gray-50 body-font bg-dark bg-center bg-wavy bg-no-repeat bg-cover">
    <div  className="container px-5 py-24 mx-auto flex flex-col mb-24 md:mb-64">
      <div className="lg:w-4/6 mx-auto">
        {/* <div className="rounded-lg h-64 overflow-hidden">
          <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1200x500"/>
        </div> */}
        <div className="flex flex-col sm:flex-row mt-10">
          <ContactDetails/>
          <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-primary sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left ">
            <p className="leading-relaxed text-lg mb-4 text-white">Sind Sie bereit für Ihr E-Commerce Projekt? Wir beraten plattformunabhängig mit Fokus auf Ihre individuellen Ziele und Anforderungen. Sichern Sie sich noch heute Ihr unverbindliche Erstgespräch. Mein Team und ich freuen uns auf Sie!  </p>
            <a className="cursor-pointer text-primary inline-flex items-center" href="mailto:hallo@digitscom.de?subject=Unverbindliches Erstgespräch: E-Commerce Einstieg&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
            ich interessiere mich für eine kostenlose Erstberatung zum Thema Einstieg in den Onlinehandel.%0D%0A%0D%0A%0D%0ABitte nehmen Sie mit mir für eine kurzfristige Terminvereinbarung Kontakt auf.%0D%0A(Untenstehende Angaben bitte vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A">Jetzt Erstgespräch sichern
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
            <div className="mark">
        

    </div>
          </div>
        </div>
      </div>
    </div>
</section>



<Footer/>

</>
    )
}

export default indexPage;

export const Head = ({data}) => {
  return (
  <>

  {/* <meta name="description" content="This is an example of a meta description. This will often show up in search results."/> */}
  {/* <title> </title> */}
  <SEO/>
  </>
  )
}

export const page = graphql`
query MyQuery {
  sanityPage(id: {eq: "-3414d425-da87-50fe-87d9-fade4982e897"}) {
    image  {
        ...ImageWithPreview
    }
    heading
    slug {
      current
    }
    text
  
    imageTwo {
        ...ImageWithPreview
    }
    imageThree {
        ...ImageWithPreview
    }
    
  }
  sanityLevelOne(id: {eq: "-0de525e9-ce21-5487-8b2b-d08fad719475"}){
    image  {
        ...ImageWithPreview
    }

  }
  sanityGlobal(id: {eq: "-8b01475b-9fbe-550a-9251-92d0c96ac077"}){
    image {
      ...ImageWithPreview
    }
  }
}


`